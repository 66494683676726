<template>
  <el-upload ref="uploadRef" class="upload-demo" drag action="#" :limit="1" :accept='img_accept'
    :http-request="ImgHttpImport" list-type="picture-card" multiple>
    <div class="dragbox w100 h-210 dp-fc ac-c fw-w" v-show="!state.imgurl">
      <img src="@/assets/img/general/img.png" class="w-88 h-88 mb-24" />
      <div class="w100 dp-f jc-c">
        请将文件拖拽至此，或<img src="@/assets/img/general/upload.png" class="w-16 h-16 ml-6 mr-3 mt-2 cu-p" /><span
          class="cu-p">点击上传</span>
      </div>
    </div>
    <template #file="{ file }">
      <div class="dragbox w100 h-210 dp-fc imgdragbox" v-show="state.imgurl">
        <img class="fileImg" :src="file.url" alt="" />
        <div class="delbox dp-fc" @click="state.imgurl = false, unref(uploadRef).clearFiles()">
          <img class="w-28 h-28" src="@/assets/img/general/delete.png">
        </div>
      </div>
    </template>
  </el-upload>
</template>
<script setup>
import { reactive, ref, unref } from "vue";
import { img_accept } from "@/utils/base/accept"
const state = reactive({
  imgurl: false,
});
const uploadRef = ref()//上传组件ref
// 图片上传方法
const ImgHttpImport = ((val) => {
  console.log('ImgHttpImport', val)
  state.imgurl = val
})
</script>
<style lang="scss" scoped >
::v-deep .el-upload-dragger {
  border: none !important;
  padding: 0 !important;
}

::v-deep .el-upload--picture-card {
  border: none !important;
}

::v-deep .el-upload--picture-card {
  --el-upload-picture-card-size: 100%;
}

::v-deep .el-upload-list--picture-card {
  --el-upload-list-picture-card-size: 100%;
  display: block;
}

.dragbox {
  border-radius: 6px;
  border: 1px solid #CCCCCC;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;

  span {
    color: #1890FF;
  }

  .fileImg {
    max-width: calc(100% - 30px);
    max-height: calc(100% - 30px);
  }
}

.imgdragbox {
  // padding: 15px;
  position: relative;

  .delbox {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.4);
  }
}

.imgdragbox:hover {
  .delbox {
    display: flex;
  }
}
</style>